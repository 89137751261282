<template>
    <div class="page formPage" id="exhibition">
        <el-row :gutter="12">
            <el-col :span="4">
                <el-input clearable placeholder="请输入展览名称" size="small" v-model="culturalName"></el-input>
            </el-col>
            <el-col :span="5">
                <el-button size="small" icon="el-icon-search"  type="primary" @click="getList(1)">查询</el-button>
                <el-button size="small" icon="el-icon-refresh-right" @click="reselt()">重置</el-button>
            </el-col>
            <el-col :span="15" style="text-align: right">
                <el-button v-if="hasPermission('exhibitionManagement:exhibitionList:add')" size="small" type="primary"
                           @click="details(0,2)">新增
                </el-button>
                <el-button :disabled="multipleSelectionIds.length == 0"
                           v-if="hasPermission('exhibitionManagement:exhibitionList:delete')" size="small" type="primary"
                           @click="Wudelete()">删除
                </el-button>
            </el-col>
        </el-row>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                ref="singleTable"
                height="calc(100vh - 245px)"
                @selection-change="handleSelectionChange"
                class="table">
            <el-table-column
                    type="selection"
                    width="55">
            </el-table-column>
            <el-table-column prop="bannerUr]" label="大图" width="120">
                <template slot-scope="scope">
                    <el-image
                            style="width: 100%"
                            :src="scope.row.banner[0].imgUrl"
                            :fit="'contain'"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="exhibitionName" label="展览名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="location" label="地点" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="startTime" label="开始日期" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.startTime}}
                </template>
            </el-table-column>
            <el-table-column prop="endTime" label="结束日期" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.endTime ? scope.row.endTime :'待定'}}
                </template>
            </el-table-column>
            <el-table-column prop="serialNumber" label="排序" width="150">
                <!--                <template slot-scope="scope">-->
                <!--                    <el-input-number size="mini" style="width: 100%" v-model="scope.row.serialNumber" @blur="handleSerialNumber(scope.row)" :min="1" :max="999" label="请输入序号（1~999 ）序号越小显示越靠前"></el-input-number>-->
                <!--                </template>-->
            </el-table-column>
            <el-table-column prop="price" label="票价（元）" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.price ? scope.row.price :'免费'}}
                </template>
            </el-table-column>
            <el-table-column prop="showAndPresentName" label="展陈状态" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="enabledState" label="启用状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.enabledState"
                            active-value="1"
                            inactive-value="0"
                            @change="stateChange(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column fixed="right" width="200" label="操作">
                <template slot-scope="scope">
                    <el-button
                            v-if="hasPermission('exhibitionManagement:exhibitionList:see')"
                            icon="el-icon-view" type="text"
                            size="mini" @click="details(scope.row.id,0)">详情
                    </el-button>
                    <el-button
                            v-if="hasPermission('exhibitionManagement:exhibitionList:edit')"
                            icon="el-icon-edit" type="text"
                            size="mini" @click="details(scope.row.id,1)">编辑
                    </el-button>
                    <el-button
                            v-if="hasPermission('exhibitionManagement:exhibitionList:delete') && scope.row.enabledState == 0"
                            icon="el-icon-delete" type="text"
                            size="mini" @click="Wudelete(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                :current-page="pageNo"
                :page-size="pageSize"
                :page-sizes="[10, 20, 50, 100]"
                :total="total"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "exhibition",
        data() {
            return {
                culturalName: '',//展厅名称
                dataList: [],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                distinguish: 0,
                multipleSelectionIds: [],//展厅多选
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //获取列表
            getList(num) {
                this.loading = true;
                if (num) {
                    this.pageNo = 1;
                }
                this.$axios(this.api.zhanLanMessage.queryAllExpire, {}, 'post').then(res => {
                    this.$axios(this.api.zhanLanMessage.zhanLanList, {
                        exhibitionName: this.culturalName,
                        current: this.pageNo,
                        size: this.pageSize,
                    }, 'post').then(res => {
                        this.loading = false;
                        this.dataList = res.data.records;
                        this.total = parseInt(res.data.total)
                    })
                })
            },

            //重置
            reselt(){
                this.culturalName = ''
                this.getList(1)
            },

            //num 0详情, 1编辑,2新增
            details(id, num) {
                if (num == 0){
                    this.$router.push({
                        path: '/exhibitionManagement/management/exhibitionDetails',
                        query: {
                            id: id,
                            num: num,
                        },
                    });
                }else if (num == 1){
                    this.$router.push({
                        path: '/exhibitionManagement/management/editexhibitionA',
                        query: {
                            id: id,
                            num: num,
                        },
                    });
                }else {
                    this.$router.push({
                        path: '/exhibitionManagement/management/addexhibitionA',
                        query: {
                            id: id,
                            num: num,
                        },
                    });
                }
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getList();
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getList();
            },


            //多选
            handleSelectionChange(val) {
                this.multipleSelectionIds = []
                val.forEach(item => {
                    if (item.enabledState == 0){
                        this.multipleSelectionIds.push(item.id)
                    }
                })
                console.log(this.multipleSelectionIds)
            },

            //修改状态
            stateChange(obj) {
                this.$axios(this.api.zhanLanMessage.zhanLanUpdateById, {...obj}, 'put').then(res => {
                    console.log(res)
                    if (res.status) {
                        this.$message.success('修改成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.getList();
                })
            },

            //删除
            Wudelete(id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let ids = []
                    if (id) {
                        ids.push(id)
                    } else {
                        ids = this.multipleSelectionIds
                    }
                    this.$axios(this.api.zhanLanMessage.zhanLanRemoveById, ids, 'post').then(res => {
                        if (res.status) {
                            this.$message.success('删除成功');
                            if (this.dataList.length == ids.length && this.pageNo > 1){
                                this.pageNo--;
                            }
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }).catch(() => {
                });
            },

            //排序改变
            handleSerialNumber(row){
                if (!row.serialNumber){
                    row.serialNumber = 1;
                }

                //接口
            },
        }
    }
</script>

<style scoped>
    .picture-format {
        font-size: 12px;
    }
</style>
